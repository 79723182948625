[id="footer"] {
	padding: 23px 0 57px;
	font-size: 14px;
	text-align: center;
	background-color: $footer-bg;
	color: $white;

	@include media('>=tablet') {
		font-size: 24px;
	}

	@include media('>=widescreen') {
		padding: 44px 0 208px;
		font-size: 30px;
	}

	.logo {
		width: 146px;
		margin: 0 auto 6px;

		@include media('>=tablet') {
			width: 200px;
		}

		@include media('>=widescreen') {
			width: 296px;
			margin: 0 auto 18px;
		}
	}
}
