// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$black: #333;
$white: #fff;
$light-blue: #598DD3;
$blue: #193862;
$blue-100: #5386CA;
$blue-200: #224068;

$footer-bg: $light-blue;
$header-bg: rgba(#195EBC, 0.52);
$gallery-text-color: $blue;

$primary: $light-blue;

$base-font-sans-serif: 'Futura', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $blue-200;
$base-background-color: $white;
$font-size-base: 20px;
$line-height-base: 1.35;
$base-font-family: $base-font-sans-serif;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: #4285D0;

// Buttons
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 36px;
$h2-font-size: 27px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: $blue-100;


$animation-speed: 0.3s;
