[id="header"] {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 70px;
	padding: 0 20px;
	z-index: 1;
	background-color: $header-bg;

	@include media('>=tablet') {
		height: 70px;
		padding: 0 20px;
		justify-content: flex-start;
		overflow: hidden;
	}

	@include media('>=widescreen') {
		padding: 0 30px;
	}

	.logo {
		// width: 105px;
		margin-left: 90px;
		margin-right: 30px;
		padding: 3px 0px 10px;
		a {
			img {
				vertical-align: middle !important;
				max-width: 150px;
			}
		}

		@include media('>=tablet') {
			width: 150px;
			margin: 0 20px 0 0;
		}

		@include media('>=widescreen') {
			width: 211px;
			margin-right: 0px;
		}
	}

	.back-link {
		left: 30px;
		top: 50%;
		transform: translateY(-50%);

		@include media('>=tablet') {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			margin: 0 20px 0 0;
		}

		&.gallery-link {
			left: auto;
			right: 30px;

			@include media('>=tablet') {
				right: 0;
				left: 0;
				margin: 0 0 0 40px;
			}

			@include media('>=widescreen') {
				margin: 0 0 0 80px;
			}

			&:after {
				@include media('>=tablet') {
					position: absolute;
					left: -20px;
					top: 50%;
					z-index: -1;
					transform: translateY(-50%);
					width: 100vw;
					height: 100px;
					background-color: rgba(#195EBC, 0.52);
					pointer-events: none;
					content: "";
				}

				@include media('>=widescreen') {
					left: -30px;
				}
			}
		}
	}

	.modal-button {
		margin-left: auto;
		color: white;
		cursor: pointer;
		width: 20%;
		min-width: 150px;
	}

	.forward-link {
		position: absolute;
		top: calc(100% + 14px);
		left: 50%;
		transform: translateX(-50%);
		min-width: 210px;
		margin-left: auto;

		@include media('>=tablet') {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			color: $white;
		}
	}
}

.back-link {
	@include animate(color);
	position: relative;
	display: inline-block;
	vertical-align: top;
	position: absolute;
	padding: 0 0 0 15px;
	font-size: 12px;
	text-transform: uppercase;
	color: rgba($white, 0.66);

	&:hover {
		color: $white;
		&:before{
			left: -5px;
		}
	}

	&:before {
		@include animate(left);
		@extend [class^="icon-"];
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		content: "\e900";
	}

	@include media('>=widescreen') {
		font-size: 20px;
		padding: 0 0 0 28px;
		margin: 0 29px 0 0;
	}

	&.gallery-link {

		.icon-slides {
			font-size: 25px;
			vertical-align: middle;

			@include media('>=widescreen') {
				margin: 0 0 0 12px;
				font-size: 51px;
			}
		}
	}
}
