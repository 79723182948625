@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?xrhe3e');
  src:  url('../fonts/icomoon.eot?xrhe3e#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?xrhe3e') format('truetype'),
    url('../fonts/icomoon.woff?xrhe3e') format('woff'),
    url('../fonts/icomoon.svg?xrhe3e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'futura';
  src: url('../fonts/futura.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'futura';
  src: url('../fonts/futurabold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-slides:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
