.main-carousel-section {
	padding: 90px 0 45px;
	color: $gallery-text-color;

	@include media('>=tablet') {
		padding: 100px 0 10px;
	}

	.heading {
		max-width: 1035px;
		width: 100%;
		margin: 0 auto 50px;
		font-size: 18px !important;
		line-height: 1.3;
		text-align: center;

		@include media('>=desktop') {
			margin: 0 auto 20px;
		}
	}

	.main-container {
		max-width: 1230px;
	}
}

.main-carousel-holder {
	position: relative;
	margin: 0 0 40px;

	.btn-prev,
	.btn-next {
		@include animate(color);
		@include size(50px);
		position: absolute;
		left: 0;
		top: 50%;
		z-index: 10;
		transform: translateY(-50%);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 52px;
		color: $primary;

		&:hover {
			color: darken($primary, 10%);
		}

		@include media('>=widescreen') {
			@include size(80px);
			font-size: 60px;
		}
	}

	.btn-next {
		left: auto;
		right: 0;
	}
}

.main-carousel {
	height: 700px !important;
	margin-top: -110px;
	margin-bottom: -100px;

	@include media('>=tablet') {
		// height: 480px !important;
	}

	.carousel-item {
		width: 260px !important;
		height: 420px !important;
		cursor: pointer;

		@include media('>=tablet') {
			width: 210px !important;
		}

		&:not(.active) {
			@include media('<phone') {
				opacity: 0 !important;
			}
		}

		img {
			width: 100%;
			box-shadow: 0px 10px 15px -10px $black;
		}
	}
}

.add-carousel {
	max-width: 1006px;
	width: 100%;
	height: 300px !important;
	margin: 0 auto;
	pointer-events: none;

	&:before {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
		width: 100%;
		height: 60px;
		background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		content: "";
	}

	@include media('>=tablet') {
		height: 150px !important;
	}

	.carousel-item {
		@extend %clearfix;
		min-height: 0px !important;
		color: $base-text-color;

		.logo {
			width: 160px;
			margin: 0 auto 20px;

			@include media('>=tablet') {
				float: left;
				margin: 0 53px 0 0;
			}
		}

		p {
			font-size: 19px !important;
			@include media('>=tablet') {
				overflow: hidden;
			}
		}
	}
}

.spotlight-slider {
	.slick-slide {
		padding: 0 15px;
	}
}
