html, body {
	height: 100%;
}

[id="wrapper"] {
	position: relative;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: block;
	}
}

[id="header"],
[id="footer"] {
	flex-shrink: 0;
}

[id="main"] {
	flex: 1 0 auto;
}

[id="footer"] {
	margin-top: auto;
}


.sky-bg {
	position: absolute;
	left: 50%;
	top: 0;
	z-index: -1;
	transform: translateX(-50%);
	width: 100vw;
	min-width: 2199px;
	height: 706px;
	background: 50% 0 no-repeat;
	background-size: 100% auto;
}

.main-container {
	max-width: 1500px;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px;
}

.hero-section {
	min-height: calc(100vh - 128px);
	padding: 70px 0;
	font-size: 13px;
	line-height: 1.6;
	color: $white;
	background: 50% 0% no-repeat;
	background-size: cover;

	@include media('>=tablet') {
		display: flex;
		align-items: center;
		padding: 145px 0 60px;
		font-size: 20px;
		line-height: 1.2;
	}

	@include media('>=widescreen') {
		min-height: calc(100vh - 460px);
		padding: 185px 0 105px;
		font-size: 28px;
	}

	.main-container {
		max-width: 1790px;
	}

	.two-columns {
		@include media('>=tablet') {
			display: flex;
			justify-content: space-between;

		}
	}

	.logo {
		max-width: 310px;
		width: 100%;
		margin: 0 auto;

		@include media('>=tablet') {
			width: 33%;
			max-width: none;
			margin: 0;
		}

		@include media('>=widescreen') {
			margin: 0 0 0 70px;
		}
	}

	.text-wrap {
		padding: 0px 12px;

		@include media('>=tablet') {
			display: flex;
			flex-direction: column;
			width: 54%;
			margin-top: 140px;
			margin-right: 50px;
			align-items: flex-start;
		}

		@include media('>=x-widescreen') {
			// padding: 0 0 89px;
		}

		> * {
			max-width: 100%;
		}
	}

	p {
		margin: 0 0 22px;
	}

	.forward-link {
		color: $white;
	}
}

.forward-link {
	@include animate(color);
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding: 0 60px 0 0;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;

	&:before {
		@include animate(transform);
		@extend [class^="icon-"];
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		content: "\e901";
	}

	&:hover {
		&:before {
			transform: translate3d(10px, -50%, 0);
		}
	}

	@include media('>=widescreen') {
		padding: 0 60px 0 0;
		font-size: 36px;
	}

	&.lg {
		margin: auto auto 8px;
		font-size: 22px;

		@include media('>=tablet') {
			font-size: 30px;
		}

		@include media('>=widescreen') {
			font-size: 30px;
		}
	}
}

.w-100 {
	width: 100% !important;
}

.thumbs {
	@extend %listreset;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	@include media('>=widescreen') {
		// padding: 26px 0 0;
	}

	li {
		position: relative;
		width: calc(33.33333% - 40px);
		max-width: 150px;
		padding: 30px;
		margin: 0 20px;

		&.active {
			.brackets {
				opacity: 1;
				visibility: visible;
			}

			img {
				opacity: 0.64;
			}
		}

		a {
			&:hover {
				img {
					opacity: 0.64;
				}
			}
		}

		img {
			@include animate(opacity);
		}

		.brackets {
			opacity: 0;
			visibility: hidden;

			&:before,
			&:after,
			span:before,
			span:after, {
				@include size(27px);
				position: absolute;
				top: 0;
				left: 0;
				border: solid #E5E5E5;
				border-width: 7px 0 0 7px;
				content: "";
			}

			&:after {
				top: auto;
				bottom: 0;
				border-width: 0 0 7px 7px;
			}

			span:before {
				left: auto;
				right: 0;
				border-width: 7px 7px 0 0;
			}

			span:after {
				top: auto;
				bottom: 0;
				left: auto;
				right: 0;
				border-width: 0 7px 7px 0;
			}
		}
	}
}

.spotlight-text {
	font-size: 16px;
}

.spotlight-section {
	@extend .clearfix;
	padding: 50px 0 68px;

	@include media('>=tablet') {
		padding: 125px 0 60px;
	}

	@include media('>=widescreen') {
		min-height: 100vh;
	}

	.main-container {
		max-width: 1546px;
	}

	.text-col {
		margin:  0 0 40px;

		@include media('>=tablet') {
			width: 60%;
			float: left;
			margin: 0;
		}

		@include media('>=widescreen') {
			width: 60%;
			padding: 0px 0 0 40px;
		}
	}

	.img-col {
		max-width: 264px;
		margin: 0 auto;

		@include media('>=tablet') {
			max-width: none;
			width: 30%;
			float: right;
			text-align: right;
		}

		img {
			max-height: 600px;
		}
	}

	.logo {
		text-align: center;
		margin: 0 0 20px;

		@include media('>=widescreen') {
			margin: 0 0 50px;
		}

		img {
			width: 100%;
			max-width: 300px;

			// @include media('>=widescreen') {
			// 	width: auto;
			// 	max-width: none;
			// }
		}
	}
}

.mobile-hide {
	@include media('<tablet') {
		display: none !important;
	}
}

.js-tab-hidden {
	display: block !important;
	left: -9999px !important;
	position: absolute !important;
	top: -9999px !important;
}

.tab-content {
	text-align: center;

	.tab {
		@include media('>=tablet') {
			@include animate(opacity);
			opacity: 0;
		}

		&.active {
			@include media('>=tablet') {
				opacity: 1;
			}
		}
	}
}
