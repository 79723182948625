/* label color */
.modal {
  border-radius: 5px !important;
  .row {
    padding: 20px 40px 0px 40px;
  }
}

.input-field label {
 color: $header-bg !important;
}
/* label focus color */
.input-field input[type=text]  {
 color: $header-bg !important;
 border-bottom: 2px solid $header-bg !important;
}

.input-field input[type=email]  {
 color: $header-bg !important;
 border-bottom: 2px solid $header-bg !important;
}

/* label focus color */
.input-field input[type=text]:focus + label {
 color: $header-bg !important;
}
/* label underline focus color */
.input-field input[type=text]:focus {
 border-bottom: 2px solid $header-bg !important;
 box-shadow: 0 1px 0 0 $header-bg !important;
}
/* valid color */
.input-field input[type=text].valid {
 border-bottom: 2px solid $header-bg !important;
 box-shadow: 0 1px 0 0 $header-bg !important;
}
/* invalid color */
.input-field input[type=text].invalid {
 border-bottom: 2px solid $header-bg !important;
 box-shadow: 0 1px 0 0 $header-bg !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
 color: $header-bg !important;
}

.btn-flat {
 color: $header-bg;
 font-weight: bold;
 font-size: 18px;
}
